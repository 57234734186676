<template>
  <!-- 代金券 -->
  <div class="container">
    <a-page-header :title="title" />
    <div class="main-content">
      <div class="ribbon-wrap">
        <a-radio-group v-model="radioValue" @change="onStatuRadioChange">
          <a-radio-button v-for="r in radioStatus" :key="r.id" :value="r.statu">
            {{ r.text }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1050, y: true }"
        :row-key="(record) => record.stock_id"
        @change="onPaginationChange"
      >
        <template slot="status" slot-scope="record">
          <a-badge
            :status="statuText[record.status].status"
            :text="statuText[record.status].text"
          />
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button type="link" :disabled="record.status !== 'running'">
            <router-link
              :to="{
                path: '/voucherIssue',
                query: { id: record.stock_id, name: record.stock_name }
              }"
            >
              发放
            </router-link>
          </a-button>
        </template>
      </my-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      title: '',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '批次名称',
          width: 150,
          dataIndex: 'stock_name'
        },
        {
          title: '批次状态',
          width: 100,
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '已发券数量',
          width: 120,
          dataIndex: 'distributed_coupons'
        },
        {
          title: '可用开始时间',
          width: 200,
          dataIndex: 'available_begin_time'
        },
        {
          title: '可用结束时间',
          width: 200,
          dataIndex: 'available_end_time'
        },
        {
          title: '批次类型',
          dataIndex: 'stock_type',
          customRender: (text) => {
            return this.$stockTypes[text]
          }
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading: true,
      pagination: {
        current: 1,
        total: 0
      },
      statuText: {
        unactivated: {
          status: 'default',
          text: '未激活'
        },
        audit: {
          status: 'default',
          text: '审核中'
        },
        running: {
          status: 'success',
          text: '运行中'
        },
        stoped: {
          status: 'default',
          text: '已停止'
        },
        paused: {
          status: 'default',
          text: '暂停发放'
        }
      },
      radioStatus: [
        {
          id: 1,
          text: '全部',
          statu: 0
        },
        {
          id: 2,
          text: '运行中',
          statu: 'running'
        },
        {
          id: 3,
          text: '暂停发放',
          statu: 'paused'
        },
        {
          id: 4,
          text: '已完成',
          statu: 'stoped'
        }
      ],
      radioValue: 0
    }
  },
  computed: {
    ...mapState(['$stockTypes'])
  },
  created() {
    this.title = this.$getPageTitle()
    this.getVoucher()
  },
  methods: {
    onStatuRadioChange() {
      // 监听状态选择
      this.getVoucher()
    },
    onPaginationChange(e) {
      this.loading = true
      this.pagination.current = e.current
      this.getVoucher()
    },
    getVoucher() {
      // 获取代金券
      const data = {
        page: this.pagination.current,
        limit: 10
      }
      if (this.radioValue) {
        data.status = this.radioValue
      }
      this.$axios.getVoucher(data).then((res) => {
        const d = res.data.data
        this.dataSource = d
        // this.pagination.total = d.count
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.ribbon-wrap {
  margin-bottom: 0;
  padding: 24px 24px 0 24px;
}
</style>
