<template>
  <!-- 公共数据源-购物商城-新增商品分类 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)">
      <template slot="extra">
        <a-button type="primary" size="large" @click="handleSubmit">
          提交
        </a-button>
      </template>
    </a-page-header>
    <div class="main-content">
      <div class="body">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item
            class="ant-form-item-input"
            ref="name"
            label="名称"
            prop="name"
          >
            <a-input
              v-model="form.name"
              placeholder="请输入名称"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item
            prop="cover"
            label="缩略图"
            extra="建议使用PNG格式图片；尺寸为200px*200px"
          >
            <a-upload
              nname="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              :data="{
                token: qiniuData.token
              }"
              @change="handleChange"
            >
              <div class="avatar-box" v-if="form.cover">
                <img class="img" :src="form.cover" alt="avatar" />
              </div>
              <div v-else>
                <a-icon :type="imgLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="editor">
        <div class="title-wrap">
          <span class="title">详情内容</span>
          <a-icon class="icon" type="info-circle" theme="filled" />
          <span class="content"
            >建议使用PNG格式图片，图片分段上传，每段不可大于500k；上传图片时，须将图片<b
              style="color: red"
              >宽设置为100%，高设置为空</b
            ></span
          >
        </div>
        <tinymce ref="editor" v-model="form.content" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import tinymce from '../../components/tinymce'
let servingId = ''
export default {
  data() {
    return {
      title: '',
      imgLoading: false,
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      form: {
        name: '',
        categoryId: undefined,
        cover: '',
        content: ''
      },
      rules: {
        // 绑定地区
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传缩略图', trigger: 'change' }],
        content: [
          { required: true, message: '请输入详情内容', trigger: 'blur' }
        ]
      },
      categoryOptions: []
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  components: {
    tinymce
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    servingId = query.servingId
    this.form.categoryId = query.categoryId
    if (servingId) {
      this.title = '编辑商品分类'
      this.getPublicGoodsInfo()
    } else {
      this.title = '新增商品分类'
    }
    this.getPunlicService()
  },
  methods: {
    getPublicGoodsInfo() {
      // 获取服务项目详情
      const data = {
        servingId: servingId
      }
      this.$axios.getPublicGoodsInfo(data).then((res) => {
        const e = res.data.data
        this.form.name = e.name
        this.form.categoryId = e.categoryId
        this.form.cover = e.cover
        this.form.content = e.content
        // console.log(this.form)
      })
    },
    getPunlicService() {
      // 获取分类
      const data = {
        page: 1,
        limit: 20
      }
      this.$axios.getPunlicService(data).then((res) => {
        this.categoryOptions = res.data.data.list
      })
    },
    handleChange(info) {
      // 图片上传
      if (info.file.status === 'uploading') {
        if (this.form.cover) {
          this.form.cover = ''
        }
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.cover = this.qiniuData.host + info.file.response.key
        this.imgLoading = false
      }
    },
    async beforeUpload() {
      // 图片上传检验
      // await this.$examineImgWH(file)
      return true
    },
    handleSubmit() {
      // 提交表单数据
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.form.content) {
            this.$message.error('请先输入详情内容')
            return
          }
          if (servingId) {
            this.form.id = servingId
            this.editPublicGoods()
          } else {
            this.addPublicGoods()
          }
        }
      })
    },
    addPublicGoods() {
      // 提交新增
      this.$axios.addPublicGoods(this.form).then(() => {
        this.$message.success('操作成功')
      })
    },
    editPublicGoods() {
      // 提交编辑
      this.$axios.editPublicGoods(this.form).then(() => {
        this.$message.success('操作成功')
      })
    }
  }
}
</script>
<style scoped>
.body,
.editor {
  padding: 24px;
  background-color: #fff;
}

.body .title,
.editor .title {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}

.ant-form-item-input {
  width: 294px;
}

.editor {
  margin-top: 16px;
}

.editor .title-wrap {
  margin-bottom: 16px;
}

.editor .title::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.editor .icon {
  opacity: 0.65;
  margin: 0 5px 0 17px;
}

.editor .content {
  color: #8c8c8c;
}
</style>
