<template>
  <!-- 公共数据源-管理商城商品 -->
  <div class="container">
    <a-page-header
      :title="title"
      sub-title="源数据管理，操作即时生效，谨慎操作！"
      @back="() => $router.go(-1)"
    />

    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span class="title-1">商品（源数据）</span>
          </div>
        </div>
        <div class="item">
          <a-button
            type="primary"
            @click="
              $jump({
                path: '/publicGoodsAdd',
                query: {
                  categoryId: categoryId
                }
              })
            "
          >
            新增商品分类
          </a-button>
        </div>
      </div>

      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1220 }"
      >
        <template slot="cover" slot-scope="record">
          <div class="cover">
            <img class="img" :src="record.cover" alt="cover" />
          </div>
        </template>
        <template slot="action" slot-scope="text, record, index">
          <a-button type="link" @click="handleOpen(index)">
            新增商品
          </a-button>
          <a-button
            type="link"
            @click="
              $jump({
                path: '/publicGoodsAdd',
                query: {
                  categoryId: categoryId,
                  servingId: record.id
                }
              })
            "
          >
            编辑
          </a-button>
          <!-- <a-button type="link">
            禁用
          </a-button> -->
        </template>
        <a-table
          class="inner-table"
          slot="expandedRowRender"
          slot-scope="innerRecord"
          :columns="innerColumns"
          :data-source="innerRecord.Info"
          :pagination="false"
          :row-key="(record) => record.id"
          :scroll="{ x: 1320 }"
        >
          <template slot="cover" slot-scope="records">
            <div class="cover">
              <img class="img" :src="records.cover" alt="cover" />
            </div>
          </template>
          <template slot="price" slot-scope="records">
            <a-statistic prefix="￥" :value="records.price" />
          </template>
          <template slot="innerAction" slot-scope="record">
            <a-button type="link" @click="handleEdit(record)">
              编辑
            </a-button>
            <a-button type="link" @click="handleDelete(record.id)">
              删除
            </a-button>
          </template>
        </a-table>
      </my-table>
    </div>

    <drawer :title="drawerTitle" :width="376" :visible="visible">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item
          class="ant-form-item-input"
          ref="name"
          label="商品名称"
          prop="name"
        >
          <a-input
            v-model="form.name"
            placeholder="请输入名称"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="price" label="售价" prop="price">
          <a-input
            v-model="form.price"
            placeholder="请输入售价"
            @blur="
              () => {
                $refs.price.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="subtitle" label="简介" prop="subtitle">
          <a-input
            v-model="form.subtitle"
            placeholder="请输入简介"
            @blur="
              () => {
                $refs.subtitle.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="specifiId" label="设备规格" prop="specifiId">
          <a-select v-model="form.specifiId" placeholder="请选择规格">
            <a-select-option
              v-for="item in specifis"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="sort" label="排序" prop="sort">
          <a-input
            v-model="form.sort"
            placeholder="请输入排序"
            @blur="
              () => {
                $refs.sort.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item
          prop="cover"
          label="展示图"
          extra="建议使用PNG格式图片；尺寸为200px*200px"
        >
          <a-upload
            nname="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="uploadUrl"
            :before-upload="beforeUpload"
            :data="{
              token: qiniuData.token
            }"
            @change="handleChange"
          >
            <div class="avatar-box" v-if="form.cover">
              <img class="img" :src="form.cover" alt="avatar" />
            </div>
            <div v-else>
              <a-icon :type="imgLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
      <div class="drawer-btn-wrap">
        <a-button @click="handleDrawerClose">
          取消
        </a-button>
        <a-button type="primary" @click="handleDrawerSubmit">
          提交
        </a-button>
      </div>
    </drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import drawer from '../../components/drawer/index'
let innerDataIndex = -1
let itemId = ''
export default {
  data() {
    return {
      categoryId: '',
      title: '',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '商品分类',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '缩略图',
          scopedSlots: { customRender: 'cover' },
          width: 200
        },
        {
          title: '创建时间',
          dataIndex: 'createAt'
        },
        {
          title: '操作',
          width: 250,
          scopedSlots: { customRender: 'action' }
        }
      ],
      innerColumns: [
        {
          title: '排序',
          dataIndex: 'sort',
          width: 60
        },
        {
          title: '编号',
          dataIndex: 'goods_tag',
          width: 100
        },
        {
          title: '商品名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '商品简介',
          dataIndex: 'subtitle',
          width: 300
        },
        {
          title: '商品规格',
          dataIndex: 'specName',
          width: 100
        },
        {
          title: '缩略图',
          width: 100,
          scopedSlots: { customRender: 'cover' }
        },
        {
          title: '默认售价',
          align: 'right',
          width: 100,
          scopedSlots: { customRender: 'price' }
        },
        {
          title: '创建时间',
          dataIndex: 'createAt'
        },
        {
          title: '操作',
          fixed: 'right',
          width: 160,
          scopedSlots: { customRender: 'innerAction' }
        }
      ],
      loading: true,
      expandedLoading: true,
      imgLoading: false,
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      drawerTitle: '',
      visible: false,
      form: {
        name: '',
        price: '',
        subtitle: '',
        cover: '',
        sort: '',
        specifiId: undefined
      },
      rules: {
        // 绑定地区
        name: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        price: [{ required: true, message: '请输入售价', trigger: 'blur' }],
        subtitle: [{ required: true, message: '请输入简介', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传缩略图', trigger: 'change' }],
        specifiId: [
          { required: true, message: '请选择规格', trigger: 'change' }
        ]
      },
      specifis: []
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  components: {
    drawer
  },
  created() {
    const query = this.$route.query
    this.title = query.name
    this.categoryId = query.id
    this.getPublicGoods()
  },
  methods: {
    getPublicGoods() {
      // 获取公共数据服务项目
      const data = {
        categoryId: this.categoryId
      }
      this.$axios.getPublicGoods(data).then((res) => {
        this.dataSource = res.data.data
        this.loading = false
      })
    },
    handleChange(info) {
      // 图片上传
      if (info.file.status === 'uploading') {
        if (this.form.cover) {
          this.form.cover = ''
        }
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.cover = this.qiniuData.host + info.file.response.key
        this.imgLoading = false
      }
    },
    async beforeUpload() {
      // 图片上传检验
      // await this.$examineImgWH(file)
      return true
    },
    getSpecification() {
      // 获取规格列表
      this.$axios.getSpecification().then((res) => {
        this.specifis = res.data.data
      })
    },
    handleOpen(i) {
      // 新增设备
      innerDataIndex = i
      this.drawerTitle = '新增设备'
      this.visible = true
      this.getSpecification()
    },
    handleEdit(e) {
      // 编辑
      this.drawerTitle = '编辑'
      this.form.name = e.name
      this.form.price = e.price
      this.form.subtitle = e.subtitle
      this.form.sort = e.sort
      this.form.cover = e.cover
      this.form.specifiId = e.specifiId
      itemId = e.id
      this.visible = true
      this.getSpecification()
    },
    handleDelete(e) {
      // 删除
      this.$confirm({
        title: '确定删除?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: e
          }
          this.$axios.delPublicGoodsItem(data).then(() => {
            this.$message.success('操作成功')
            this.getPublicGoods()
          })
        }
      })
    },
    handleDrawerClose() {
      // 关闭表单抽屉
      this.visible = false
      itemId = ''
      this.$refs.ruleForm.resetFields()
    },
    handleDrawerSubmit() {
      // 提交表单数据
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (itemId) {
            this.form.id = itemId
            this.$axios.editPublicGoodsItem(this.form).then(() => {
              this.$message.success('操作成功')
              this.handleDrawerClose()
              this.getPublicGoods()
            })
          } else {
            this.form.servingId = this.dataSource[innerDataIndex].id
            this.$axios.addPublicGoodsItem(this.form).then(() => {
              this.$message.success('操作成功')
              this.handleDrawerClose()
              this.getPublicGoods()
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.cover {
  width: 50px;
  height: 50px;
}

.inner-table {
  background-color: #fafafa;
}
</style>
